// chartConfig.js
export const getChartConfig = (mainChartColor, chartTypeValue, dataStreamValue, onLegendClick) => {
    return {
      type: chartTypeValue,
      data: {
        datasets: [
          {
            label: dataStreamValue.name,
            data: [],
            borderColor: mainChartColor,
            fill: false,
            spanGaps: true,
            yAxisID: 'y',
            pointBorderWidth: 0,
            pointRadius: 0,
            borderWidth: 1,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: true,
            onClick: onLegendClick
          }
        },
        animation: {
          duration: 0
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          }
        }
      }
    };
  };