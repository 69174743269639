// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

import trading from './trading_utils.js';
import chartHelper from './chart_helper.js';
import { getChartConfig } from './chart_config.js';

import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

export default class extends Controller {
  static targets = ["searchResults", "searchResultTemplate", 'overlays', 'overlayTemplate']

  static values = {
    chartType: {type: String, default: 'line'},
    dataStream: Object,
    engine: Object,
    chartFunction: String,
    start: String,
    stop: String,
    useColors: {type: Boolean, default: true},
    scaleType: {type: String, default: 'logarithmic'},
    overlayIds: {type: Array, default: []},
    overlayNames: Array,
    searchResults: Array
  }

  onNewData(data){
    this.fetchData(this.dataStreamValue.id);
  }

  scaleChanged(event){
    this.scaleTypeValue = event.target.value;
    this.chart.options.scales.y.type = this.scaleTypeValue;
    this.chart.update();
  }

  startChanged(event){
    this.startValue = event.target.value;
    this.fetchData();
  }

  updateSearchResults(data) {
    const resultsContainer = this.searchResultsTarget
    const templateContent = this.searchResultTemplateTarget
    
    // Clear previous results
    resultsContainer.innerHTML = ""
    
    if (!data.length) {
      resultsContainer.innerHTML = '<p class="text-muted p-3">No results found</p>'
      return
    }
  

    data.forEach(item => {
      // Clone template
      const resultElement = templateContent.content.cloneNode(true)
      const container = resultElement.querySelector('.search-result')
      
      // Populate fields
      resultElement.querySelector('.result-title').textContent = item.name
      if (item.path) {
        resultElement.querySelector('.result-description').textContent = item.path
      }
      
      // Add data attribute and click handler
      container.dataset.frameId = item.id
      container.addEventListener('click', () => this.addOverlay(item.id, item.name))
      
      resultsContainer.appendChild(resultElement)
    })
  }

  search(event){
    if (event.key === 'Enter') {
      event.preventDefault();
      const query = event.target.value.toLowerCase();
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    
      fetch(`/engines/${this.engineValue.id}/data_frames/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ query })
      })
      .then(response => response.json())
      .then(data => {
        this.updateSearchResults(data)
        
      })
      .catch(error => console.error('Error fetching search results:', error));
    }
  }

  addOverlay(id, name) {
    // Keep existing ID/name tracking
    if (!this.overlayIdsValue.includes(id)) {
      this.overlayIdsValue.push(id);
      this.overlayNamesValue.push(name);
      this.fetchOverlayData(id, name, this.overlayIdsValue.length - 1);
    }
  
    // Add visual overlay element
    const overlaysContainer = this.overlaysTarget
    const template = this.overlayTemplateTarget
    
    // Check if overlay already exists
    if (!overlaysContainer.querySelector(`[data-overlay-id="${id}"]`)) {
      const overlayElement = template.content.cloneNode(true)
      const container = overlayElement.querySelector('.overlay-item')
      
      // Set data and content
      container.dataset.overlayId = id
      overlayElement.querySelector('.overlay-title').textContent = name
      
      // Optional: Add remove button handler
      const removeButton = overlayElement.querySelector('.remove-overlay')
      if (removeButton) {
        removeButton.addEventListener('click', (e) => {
          e.stopPropagation()
          this.removeOverlay(id)
        })
      }

      // Optional: Add apply colors button handler
      const toggleColorsButton = overlayElement.querySelector('.toggle-overlay-colors')
      if (toggleColorsButton) {
        toggleColorsButton.addEventListener('click', (e) => {
          e.stopPropagation()
          this.toggleOverlayColors(id)
        })
      }
  

      const toggleLogButton = overlayElement.querySelector('.toggle-overlay-log')
      if (toggleLogButton) {
        toggleLogButton.addEventListener('click', (e) => {
          e.stopPropagation()
          this.toggleOverlayLog(id)
        })
      }
  
      overlaysContainer.appendChild(overlayElement)
    }
  }

  removeOverlay(id) {
    // Remove overlay ID and name from tracking arrays
    const overlayIndex = this.overlayIdsValue.indexOf(id);
    if (overlayIndex > -1) {
      this.overlayIdsValue.splice(overlayIndex, 1);
      this.overlayNamesValue.splice(overlayIndex, 1);
    }

    // Remove overlay element from DOM
    const overlayElement = this.overlaysTarget.querySelector(`[data-overlay-id="${id}"]`);
    if (overlayElement) {
      overlayElement.remove();
    }

    // Remove overlay dataset and scale from chart
    this.chart.data.datasets.splice(overlayIndex + 1, 1);
    delete this.chart.options.scales[`y${overlayIndex + 2}`];
    delete this.chart.options.plugins.annotation.annotations[`lastValue_${overlayIndex}`];

    // Update chart
    this.chart.update();
  }

  async fetchOverlayData(overlayId, name, index) {
    const overlayResponse = await fetch(`/engines/${this.engineValue.id}/data_frames/${overlayId}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
    const overlayData = await overlayResponse.json();
    const formattedOverlayData = overlayData.map(point => ({x: point.x, y: point.y}));

    const overlayDataSet = trading.createOverlayDataset(
      name,
      formattedOverlayData,
      index
    );

    this.chart.options.scales[`y${index + 2}`] = trading.createOverlayScale(
      'linear',
      formattedOverlayData,
      index
    );

    const lastValue = formattedOverlayData[formattedOverlayData.length - 1].y;
    this.chart.options.plugins.annotation.annotations[`lastValue_${index}`] = 
      trading.createLastValueAnnotation(lastValue, `y${index + 2}`);

    this.chart.data.datasets.push(overlayDataSet);
    this.chart.update();
  }

  async fetchData(){
    let mainDataSet = this.chart.data.datasets[0];
    // ...existing code...

    const response = await fetch(`/engines/${this.engineValue.id}/data_frames/${this.dataStreamValue.id}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
    const lineData = await response.json();
    const formattedLineData = lineData.map(point => ({x: point.x, y: point.y}));

    mainDataSet.data = formattedLineData;

    if(this.overlayIdsValue.length > 0 && !this.overlayIdsValue.includes('none')){
      this.chart.data.datasets = [this.chart.data.datasets[0]];
      this.chart.options.plugins.annotation.annotations = {};

      for (let i = 0; i < this.overlayIdsValue.length; i++) {
        await this.fetchOverlayData(this.overlayIdsValue[i], i);
      }
    }

    trading.addHalvingAnnotations(this.chart, mainDataSet);

    this.chart.update();
  }
  
  handleLegendClick(e, legendItem) {
    const index = legendItem.datasetIndex;
    const meta = this.chart.getDatasetMeta(index);
    meta.hidden = meta.hidden === null ? !this.chart.data.datasets[index].hidden : null;
    this.chart.update();
  }

  async connect() { // use async because fetch returns a Promise

    this.mainChartColor = chartHelper.getMainColor(); // you can change the color as per your requirement

    const ctx = this.element.querySelector("canvas").getContext("2d");
    const chartConfig = getChartConfig(this.mainChartColor, this.chartTypeValue, this.dataStreamValue, this.handleLegendClick.bind(this));
    this.chart = new Chart(ctx, chartConfig);
    chartHelper.addChartCrosshair(this.chart);

    this.fetchData();
     
  }

  toggleOverlayColors(id) {
    const overlayIndex = this.overlayIdsValue.indexOf(id);
    if (overlayIndex > -1) {
      const overlayDataset = this.chart.data.datasets[overlayIndex + 1];
      if (overlayDataset.originalData) {
        // If originalData exists, it means spectrum is currently applied, so we remove it
        overlayDataset.data = overlayDataset.originalData;
        delete overlayDataset.originalData;
      } else {
        // Otherwise, we apply the spectrum
        overlayDataset.originalData = overlayDataset.data;
        const colors = trading.buildSpectrumColors(
          trading.reScaleArray(overlayDataset.data.map(m => m.y), 0, 100),
          false,
          'rainbow'
        );
        trading.applyDatasetStyling(overlayDataset, overlayDataset.data, colors);
        // apply same colors to main  dataset
        const mainDataset = this.chart.data.datasets[0];
        trading.applyDatasetStyling(mainDataset, overlayDataset.data, colors);

      }
      this.chart.update();
    }
  }

  toggleOverlayLog(id) {
    const overlayIndex = this.overlayIdsValue.indexOf(id);
    if (overlayIndex > -1) {
      const currentScale = this.chart.options.scales[`y${overlayIndex + 2}`];
      currentScale.type = currentScale.type === 'logarithmic' ? 'linear' : 'logarithmic';
      this.chart.update();
    }
  }
}
