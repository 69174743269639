import React from "react";
import PropTypes from "prop-types";

class DataFramesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    alert('nicely')
  }

  render () {
    return (
      <React.Fragment>
        <div className="relative z-10" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-500/25 transition-opacity" aria-hidden="true"></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <div className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all">
              <div className="grid grid-cols-1">
                <input type="text" className="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm" placeholder="Search..." role="combobox" aria-expanded="false" aria-controls="options" />
                <svg className="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                  <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clipRule="evenodd" />
                </svg>
              </div>
              <ul className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800" id="options" role="listbox">
                <li className="cursor-default select-none px-4 py-2" id="option-1" role="option" tabIndex="-1">Leslie Alexander</li>
                <li className="cursor-default select-none px-4 py-2" id="option-2" role="option" tabIndex="-1">Michael Foster</li>
                <li className="cursor-default select-none px-4 py-2" id="option-3" role="option" tabIndex="-1">Dries Vincent</li>
                <li className="cursor-default select-none px-4 py-2" id="option-4" role="option" tabIndex="-1">Lindsay Walton</li>
                <li className="cursor-default select-none px-4 py-2" id="option-5" role="option" tabIndex="-1">Courtney Henry</li>
              </ul>
              <p className="p-4 text-sm text-gray-500">No people found.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DataFramesSearch.propTypes = {
  name: PropTypes.string
};
export default DataFramesSearch
