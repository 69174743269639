// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import * as echarts from 'echarts';

export default class extends Controller {
    static targets = ['heatmap']

    static values = {
        dataStream: Object,
        engine: Object,
    }



    async fetchData(){
        const response = await fetch(`/engines/${this.engineValue.id}/data_frames/${this.dataStreamValue.id}/chart_data?include_data=true`);
        const data = await response.json();
        console.log(data);
        this.showHeatmap(data);

    }


    async connect() { // use async because fetch returns a Promise

        console.log('connected');

        this.fetchData();
        
    }

 

// ...existing code...
showHeatmap(rawData) {
    const timestamps = [...new Set(rawData.map(item => item.x))];

    // Collect all possible prices from bids and asks
    const pricesSet = new Set();
    rawData.forEach(item => {
      const bids = item.data.bids || [];
      const asks = item.data.asks || [];
      bids.forEach(([p]) => pricesSet.add(parseFloat(p)));
      asks.forEach(([p]) => pricesSet.add(parseFloat(p)));
    });
    const prices = [...pricesSet].sort((a, b) => a - b);

    // Build heatmap data
    const heatmapData = [];
    rawData.forEach(item => {
      const xIndex = timestamps.indexOf(item.x);
      const bids = item.data.bids || [];
      const asks = item.data.asks || [];
      bids.forEach(([price, count]) => {
        heatmapData.push([xIndex, prices.indexOf(parseFloat(price)), parseFloat(count)]);
      });
      asks.forEach(([price, count]) => {
        heatmapData.push([xIndex, prices.indexOf(parseFloat(price)), parseFloat(count)]);
      });
    });

    // Build line data on the same Y axis
    // item.y must also be mapped to the price index
    const lineData = rawData.map(item => {
      const xIndex = timestamps.indexOf(item.x);
      const yIndex = prices.indexOf(item.y);
      return [xIndex, yIndex];
    });

    const chart = echarts.init(this.heatmapTarget);
    chart.setOption({
      tooltip: { trigger: 'item' },
      xAxis: {
        type: 'category',
        data: timestamps,
        name: 'Timestamp'
      },
      // Single y-axis for both heatmap and line
      yAxis: {
        type: 'category',
        data: prices,
        name: 'Price'
      },
      visualMap: { min: 0, calculable: true },
      series: [
        {
          name: 'Heatmap',
          type: 'heatmap',
          data: heatmapData
        },
        {
          name: 'Line',
          type: 'line',
          data: lineData,
          symbol: 'circle',
          smooth: true
        }
      ]
    });
}
// ...existing code...

}
