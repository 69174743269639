// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import { createConsumer } from "@rails/actioncable"


export default class extends Controller {

  static values = {
    chartType: {type: String, default: 'line'},
    dataStream: Object,
    objective: Object,
    objectiveDataStream: Object,
    chartFunction: String,
    start: String,
    stop: String,
    scaleType: {type: String, default: 'logarithmic'},
    overlayId: {type: String, default: 'none'}
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.dataStreamSubscription);
  }

  onNewData(data){
    this.fetchData(this.dataStreamValue.id);
  }

  scaleChanged(event){
    this.scaleTypeValue = event.target.value;
    this.chart.options.scales.y.type = this.scaleTypeValue;
    this.chart.update();
  }

  startChanged(event){
    this.startValue = event.target.value;
    this.fetchData();
  }

  overlayChanged(event){
    
    this.overlayIdValue = event.target.value;
    if(this.overlayId == 'none'){
      this.chart.data.datasets.pop();
      this.chart.update();
    }else{
      this.fetchData()
    }
  }

  subscribeToData(id){
    this.consumer = createConsumer();

    this.dataStreamSubscription = this.consumer.subscriptions.create(
      {
        channel: 'ForwardCable::DataStreamChannel',
        data_stream_id: id
      },
      {
        received: this.onNewData.bind(this),
      }
    )
  }

  toUnixTimestamp(dateString) {
    var date = new Date(dateString);
    return date.getTime();
}

  async fetchData(){
    let mainDataSet = this.chart.data.datasets[0];
    let keyResultDataSet = this.chart.data.datasets[1];
    let start = this.objectiveValue.start_date;
    let stop = this.objectiveValue.end_date;
    const response = await fetch(`/data_streams/${this.dataStreamValue.id}/chart_data?function=${this.objectiveDataStreamValue.function}&start=${start}&stop=${stop}`);
    const lineData = await response.json();
    const formattedLineData = lineData.map(point => ({x: point.x, y: point.y}));
    
    mainDataSet.data = formattedLineData;

    console.log(this.objectiveDataStreamValue)
    if(this.objectiveDataStreamValue.target_value != null){
        keyResultDataSet.data = [{x: this.toUnixTimestamp(this.objectiveValue.start_date), y: this.objectiveDataStreamValue.initial_value}, {x: this.toUnixTimestamp(this.objectiveValue.end_date), y: this.objectiveDataStreamValue.target_value}];
        
        let min = Math.min(this.objectiveDataStreamValue.initial_value, this.dataStreamValue.last_value_received);
        let max = Math.max(this.objectiveDataStreamValue.target_value, this.dataStreamValue.last_value_received);
        
        this.chart.options.scales.y.min = min;
        this.chart.options.scales.y.max = max;
        //this.objectiveValue.initial_va

    }
    
    this.chart.update();

  }

  async connect() { // use async because fetch returns a Promise
    const ctx = this.element.querySelector("canvas").getContext("2d");
    const datasets = {
      datasets: [
     
        {
          label: this.dataStreamValue.name,
          data: [],
          borderColor: '#4B5663', // you can change the color as per your requirement
          borderWidth: 1,
          pointRadius: 1,
          fill: true,
          spanGaps: true,
          yAxisID: 'y'
        },
        {
            label: 'Key result',
            data: [],
            borderColor: '#4B5663', // you can change the color as per your requirement
            borderWidth: 1,
            pointRadius: 1,
            fill: false,
            spanGaps: true,
            yAxisID: 'y'
        }
      ]
    };

    this.chart = new Chart(ctx, {
      type: this.chartTypeValue,
      data: datasets,
      options: {
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: true
            }
          },
          y: {
            beginAtZero: true,
            display: true,
            type: this.scaleTypeValue,
            grid: {
              display: false
            }
          }
        }
      }
    });

    this.subscribeToData(this.dataStreamValue.id)
    this.fetchData();
  }
}
