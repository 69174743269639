chartHelper = {};


chartHelper.addChartCrosshair = (chart) => {
    
    function throttle(func, limit) {
        let inThrottle;
        return function(...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
        }
    }
  
    const overlay = document.createElement('canvas');
    overlay.style.position = 'absolute';
    overlay.style.pointerEvents = 'none';
    chart.canvas.parentNode.appendChild(overlay);
    
    const resizeOverlay = () => {
      overlay.width = chart.canvas.width;
      overlay.height = chart.canvas.height;
      overlay.style.left = chart.canvas.offsetLeft + 'px';
      overlay.style.top = chart.canvas.offsetTop + 'px';
    };
    
    resizeOverlay();
    window.addEventListener('resize', resizeOverlay);
  
    const ctx = overlay.getContext('2d');
    
    const drawCrosshair = throttle((x, y) => {
      ctx.clearRect(0, 0, overlay.width, overlay.height);
      ctx.setLineDash([3, 3]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
      
      // Draw vertical line
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, overlay.height);
      ctx.stroke();
      
      // Draw horizontal line
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(overlay.width, y);
      ctx.stroke();
    }, 16);
  
    chart.canvas.addEventListener('mousemove', (e) => {
      const rect = chart.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      drawCrosshair(x, y);
    });
  
    chart.canvas.addEventListener('mouseout', () => {
      ctx.clearRect(0, 0, overlay.width, overlay.height);
    });
};

chartHelper.getMainColor = () => {
    let color = 'rgb(222, 226, 230)';
    let colorMode = localStorage.getItem('colorMode');

    if(colorMode == 'dark'){
      //this.changeColorMode('dark');
    }else if(colorMode == 'light'){
      color = '#4B5663';
    }else{
    }
    return color;
}

export default chartHelper;