// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import bootstrap from "./vendor/bootstrap.min.js";

import "./controllers/forward/index.js"

import "./vendor/bootstrap.min.js"
import "./vendor/fontawesome.all.js"
import "./vendor/feather.min.js"
import "./vendor/echarts.js"
import "chartkick/chart.js"


//import "./components/forward/expeditions/NewLogEntry.js"
import 'highlight.js/styles/github.css';
import "./controllers/forward/index.js" // stimulus controllers

import "./components/forward/index.jsx" // react components

// Start StimulusJS
import { Application, Controller } from "@hotwired/stimulus"
import TextareaAutogrow from 'stimulus-textarea-autogrow'

const application = Application.start();
application.register('textarea-autogrow', TextareaAutogrow)

const feather = require("feather-icons");

import embed from "vega-embed"
window.vegaEmbed = embed
window.dispatchEvent(new Event("vega:load"))


document.addEventListener("turbo:load", function() {
    feather.replace();
})

// prevent submenu from closing main menu on click
document.querySelectorAll('.dont-close-toggle').forEach((button) => {
    button.addEventListener('click', (e) => {
      e.stopPropagation(); // Prevent the menu from closing
    });
});

document.addEventListener('DOMContentLoaded', function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});



// Import and register all TailwindCSS Components
/*Æimport { Alert, Autosave, Modal, Tabs, Toggle, Slideover } from "tailwindcss-stimulus-components"

import Dropdown from 'stimulus-dropdown'
import Popover from 'stimulus-popover'

application.register('alert', Alert)
application.register('autosave', Autosave)
//application.register('dropdown', Dropdown)
//application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
//application.register('slideover', Slideover)

application.register('dropdown', Dropdown)
//console.log("I am the default forwardr application.js")
*/





