import React from 'react';
import ReactDOM from 'react-dom';

import DataFramesSearch from './DataFramesSearch';

const components = {
    DataFramesSearch,
};

function renderReactComponents() {
document.querySelectorAll('[data-react-component]').forEach(node => {
    const componentName = node.dataset.reactComponent;
    const props = JSON.parse(node.dataset.reactProps);
    const Component = components[componentName];
    if (Component) {
    ReactDOM.render(<Component {...props} />, node);
    }
});
}

document.addEventListener('DOMContentLoaded', renderReactComponents);
document.addEventListener('turbo:load', renderReactComponents);